import { CircularProgress, FormControl, Grid, Input, makeStyles, Snackbar, TextField } from '@material-ui/core';
import { navigate } from 'gatsby';
import React from 'react';
import content from '../content/contactForm.json';
import { Controller, useForm } from 'react-hook-form';
import Colors from '../styles/colors';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import Dimensions from '../styles/dimensions';
import service from '../service/service';
import ContactData from '../types/contactData';
import { Alert } from '@material-ui/lab';
import gtagReport from '../helpers/gtagReport';
// @ts-ignore
import RedErrorIcon from '../images/offer/error_red_warning_icon.svg';

enum Severity {
	success = 'success',
	error = 'error',
	info = 'info',
	warning = 'warning',
}

interface SnackbarData {
	open: boolean;
	severity: Severity;
	message: string;
}

const ContactForm = () => {
	const [loading, setLoading] = React.useState(false);
	const [snackbarData, setSnackbarData] = React.useState<SnackbarData>({
		open: false,
		severity: Severity.success,
		message: '',
	});

	const classes = useClasses();

	const { control, handleSubmit, getValues, errors, reset } = useForm();

	const onSubmit = React.useCallback((values) => {
		if (typeof window !== 'undefined') {
			setLoading(true);

			gtagReport(window.location.pathname);

			service
				.contact(ContactData.fromForm(values), window.location.pathname)
				.then((response) => {
					if (response === true) {
						if (window.location.pathname.startsWith('/probetraining')) {
							navigate('/danke-schon');
						} else {
							setSnackbarData({
								open: true,
								severity: Severity.success,
								message: content.alertMessages.success,
							});
							reset();
						}
					} else {
						setSnackbarData({ open: true, severity: Severity.error, message: content.alertMessages.error });
					}
				})
				.catch(() => {
					setSnackbarData({ open: true, severity: Severity.error, message: content.alertMessages.error });
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, []);

	const validateEmail = React.useCallback((value: string) => {
		if (value !== '' || getValues('phone') !== '') {
			return true;
		} else {
			return false;
		}
	}, []);

	const validatePhone = React.useCallback((value: string) => {
		if (value !== '' || getValues('email') !== '') {
			return true;
		} else {
			return false;
		}
	}, []);

	const handleSnackbarClose = React.useCallback(() => {
		setSnackbarData({ open: false, severity: Severity.success, message: '' });
	}, []);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
			<Grid container>
				{content.fields.map((item, key) => (
					<React.Fragment key={key}>
						{key !== 4 && (
							<Grid item xs={12} sm={6} classes={{ root: classes.gridItem }}>
								<FormControl
									fullWidth
									style={key % 2 === 0 ? { paddingRight: '1em' } : { paddingLeft: '1em' }}
									classes={{ root: classes.formControlRoot }}>
									<Controller
										as={<Input />}
										defaultValue=""
										control={control}
										name={item.name}
										placeholder={item.placeholder}
										type={item.type}
										rules={
											item.name === 'email'
												? { validate: validateEmail }
												: item.name === 'phone'
												? { validate: validatePhone }
												: { required: item.fieldError }
										}
										classes={{
											root: classes.input,
											input: classes.input,
											underline: classes.inputUnderline,
										}}
									/>
								</FormControl>
								<div
									className={classes.errorContainer}
									style={
										key % 2 === 0
											? {
													paddingRight: '1.3333333333333333em',
													visibility: errors[item.name] ? 'visible' : 'hidden',
											  }
											: {
													paddingLeft: '1.3333333333333333em',
													visibility: errors[item.name] ? 'visible' : 'hidden',
											  }
									}>
									<img src={RedErrorIcon} alt="" loading="lazy" />
									<span className={classes.errorSpan}>{item.fieldError}</span>
								</div>
							</Grid>
						)}
						{key === 4 && (
							<Grid item xs={12}>
								<FormControl fullWidth classes={{ root: classes.gridItem }}>
									<Controller
										as={<TextField />}
										defaultValue=""
										control={control}
										name={item.name}
										placeholder={item.placeholder}
										type={item.type}
										rules={{ required: item.fieldError }}
										multiline
										rows={5}
										classes={{ root: classes.textArea }}
									/>
								</FormControl>
								<div
									className={classes.errorContainerLast}
									style={{ visibility: errors[item.name] ? 'visible' : 'hidden' }}>
									<img src={RedErrorIcon} alt="" loading="lazy" />
									<span className={classes.errorSpan}>{item.fieldError}</span>
								</div>
							</Grid>
						)}
					</React.Fragment>
				))}
				<Grid item xs={12} classes={{ root: classes.buttonColumn }}>
					<button type="submit" className={classes.button} disabled={loading}>
						{content.submit}
					</button>
					{loading && <CircularProgress classes={{ circle: classes.spinnerCircle }} />}
				</Grid>
			</Grid>
			<Snackbar open={snackbarData.open} autoHideDuration={3000} onClose={handleSnackbarClose}>
				<Alert
					onClose={handleSnackbarClose}
					severity={snackbarData.severity}
					variant="filled"
					classes={{ message: classes.snackbarAlert }}>
					{snackbarData.message}
				</Alert>
			</Snackbar>
		</form>
	);
};

export default ContactForm;

const useClasses = makeStyles({
	form: {
		'& .MuiInput-root:hover::before': {
			borderColor: Colors.dividerGray,
		},
	},
	gridItem: {
		marginBottom: '2.25em',
	},
	formControlRoot: {
		boxSizing: 'border-box',
		'@media (max-width: 599px)': {
			padding: '0 !important',
		},
	},
	input: {
		fontFamily: FiraSans.book,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.textLightGray,
		'&::placeholder': {
			opacity: 0.7,
		},
	},
	inputUnderline: {
		'&:after, &:before': {
			borderBottomColor: Colors.dividerGray,
		},
	},
	textArea: {
		fontFamily: FiraSans.book,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.textLightGray,
		'& textarea': {
			fontFamily: FiraSans.book,
			fontSize: Dimensions.smallText.fontSize,
			lineHeight: Dimensions.smallText.lineHeight,
			color: Colors.textLightGray,
		},
		'& textarea::placeholder': {
			opacity: 0.7,
		},
		'& :after, & :before': {
			borderBottomColor: Colors.dividerGray,
		},
	},
	button: {
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		backgroundColor: Colors.limeGreen,
		border: 0,
		borderRadius: '8px',
		padding: '0.75em 2.25em',
		outline: 0,
		cursor: 'pointer',
		marginRight: '1em',
	},
	errorContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		color: Colors.errorRed,
		position: 'relative',
		top: '1.5625em',
		marginTop: '-1.0625em',
	},
	errorContainerLast: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		color: Colors.errorRed,
		position: 'relative',
		top: '-1.5em',
		marginTop: '-1.0625em',
	},
	errorSpan: {
		marginLeft: '0.6666666666666666em',
	},
	spinnerCircle: {
		stroke: Colors.limeGreen,
	},
	buttonColumn: {
		marginTop: '1.25em',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	snackbarAlert: {
		fontFamily: FiraSans.book,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
	},
});
