import { Grid, makeStyles } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import ContactForm from '../components/contactForm';
import Layout from '../components/layout';
import SEO from '../components/seo';
import content from '../content/contact/page.json';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import Colors from '../styles/colors';
import Dimensions, { defaultWidth, narrowWidth } from '../styles/dimensions';

const Contact = () => {
	const classes = useClasses();

	return (
		<Layout>
			<SEO title="Kontakt" />

			<div className={classes.outerContainer}>
				<section className={classes.infoSection}>
					<Grid container>
						<Grid item xs={12} lg={4} className={classes.studioColumn}>
							<h5 className={classes.infoHeader}>{content.studio.title}</h5>
							<p className={classes.infoText}>{content.studio.text}</p>
						</Grid>
						<Grid item xs={12} lg={4}>
							<div className={classes.innerDivTop}>
								<h5 className={classes.infoHeader}>{content.registrationNumber.title}</h5>
								<p className={classes.infoText}>{content.registrationNumber.text}</p>
							</div>

							<div className={classes.innerDivBottom}>
								<h5 className={classes.infoHeader}>{content.email.title}</h5>
								<p className={classes.infoText}>{content.email.text}</p>
							</div>
						</Grid>
						<Grid item xs={12} lg={4}>
							<h5 className={classes.infoHeader}>{content.management.title}</h5>
							<p className={classes.infoText}>{content.management.text}</p>
						</Grid>
					</Grid>
				</section>

				<section>
					<h1 className={classes.header}>{content.title}</h1>
					<Grid container>
						<Grid item lg={6} className={classes.contactColumn}>
							<ContactForm />
						</Grid>
						<Grid item lg={6} className={classes.imageCol}>
							<StaticImage
								src="../images/contact/Kontaktiere-uns.jpg"
								alt={content.image.alt}
								className={classes.image}
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
							/>
						</Grid>
					</Grid>
				</section>
			</div>
		</Layout>
	);
};

export default Contact;

const useClasses = makeStyles({
	outerContainer: {
		minHeight: 'calc(100vh - 5.25em)',
		boxSizing: 'border-box',
		paddingTop: '6.75em',
		paddingBottom: '4em',
		maxWidth: defaultWidth,
		margin: '0 auto',
		'@media(max-width: 1231px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
		'@media (max-width: 1279px)': {
			paddingBottom: '4.25em',
			paddingTop: '3.25em',
			maxWidth: narrowWidth,
		},
	},
	infoSection: {
		paddingBottom: '10.25em',
		'@media (max-width: 1279px)': {
			paddingBottom: '4em',
		},
	},
	header: {
		color: Colors.textBlack,
		marginTop: 0,
		marginBottom: '1.2em',
		'@media (max-width: 1279px)': {
			marginBottom: '0.7em',
		},
	},
	infoHeader: {
		color: Colors.textGray,
		margin: 0,
	},
	infoText: {
		fontFamily: FiraSans.book,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		color: Colors.textGray,
		whiteSpace: 'pre-wrap',
		margin: 0,
	},
	innerDivTop: {
		marginBottom: '1.25em',
	},
	innerDivBottom: {
		'@media(max-width: 1279px)': {
			marginBottom: '1.25em',
		},
	},
	image: {
		maxWidth: '100%',
		'@media (max-width: 1279px)': {
			display: 'block',
			margin: '0 auto',
		},
	},
	imageCol: {
		'@media (max-width: 1279px)': {
			order: 1,
			marginBottom: '2.5em',
			width: '100%',
		},
		'@media (min-width: 1280px)': {
			paddingLeft: '8.33333333%',
			position: 'relative',
			top: '-6.75em',
		},
	},
	contactColumn: {
		'@media (max-width: 1279px)': {
			order: 2,
		},
	},
	studioColumn: {
		marginBottom: '2em',
	},
});
