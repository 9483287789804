class ContactData {
	public firstname: string;
	public lastname: string;
	public phone: string;
	public email: string;
	public message: string;

	constructor(firstname: string, lastname: string, phone: string, email: string, message: string) {
		this.firstname = firstname;
		this.lastname = lastname;
		this.phone = phone;
		this.email = email;
		this.message = message;
	}

	public toJSON() {
		return {
			firstname: this.firstname,
			lastname: this.lastname,
			phone: this.phone,
			email: this.email,
			message: this.message,
		};
	}

	public static fromForm(maybe: any): ContactData {
		return new ContactData(maybe.firstname, maybe.lastname, maybe.phone, maybe.email, maybe.message);
	}
}

export default ContactData;
